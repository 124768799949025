import { Scene, PerspectiveCamera, WebGLRenderer, Vector2, PlaneBufferGeometry, Mesh, ShaderMaterial} from 'three';
import other from './shaders/fragment.glsl?raw'



export default class Sketch{
    constructor(options){
        console.log('build')
        this.startingTime = 0;
        this.lastTime = 0;
        this.timeLoop = 0;
        this.timeLoopMs = 0
        this.deltaTimeLoop = 0;
        this.time = 0;
        this.container = options.dom;
        this.dividedDelta = (1/60 * 1000);
        this.scene = new Scene();

        console.log('Refresh the page for new Colors !')

        this.width = document.clientWidth;
        this.height = document.clientHeight;

        this.camera = new PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1000 );

        this.renderer = new WebGLRenderer( { 
            antialias: true
        } );

        this.titles = document.querySelectorAll('.js-animateText')
        this.footer = document.querySelectorAll('.js-animateText--invert')

        for(const titles of this.titles) {
          titles.innerHTML = this.splitText(titles.textContent)
        }

        for(const footer of this.footer) {
          footer.innerHTML = this.splitText(footer.textContent)
        }

        this.redRandom = this.generateRandomDecimalInRangeFormatted(0, 0.5, 3);
        this.greenRandom = this.generateRandomDecimalInRangeFormatted(0, 0.25, 3);
        this.blueRandom = this.generateRandomDecimalInRangeFormatted(0.25, 1, 3);
        
        this.titlesLetter = document.querySelectorAll('.js-animateText span')
        this.footerLetter = document.querySelectorAll('.js-animateText--invert span')

        this.birthYear = document.querySelector('.js-year')

        this.year = this.getAge("2000/11/3");

        this.birthYear.textContent = this.year

        


        this.uniforms = {
          u_time: { type: "f", value: Math.random() * 100.0 },
          u_resolution: { type: "v2", value: new Vector2() },
          u_red: { value: this.redRandom },
          u_green: { value: this.greenRandom },
          u_blue: { value: this.blueRandom }
        };
        
        
        this.container.appendChild( this.renderer.domElement );


        let allDone = []

        Promise.all(allDone).then(()=>{
            this.bindMethods()
            this.resize()
            this.events();
            this.addPlane()
            this.render()
        })
    }

    bindMethods() {
      this.render = this.render.bind(this)
    }

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
    }


    splitText(text) {
      const html = text.split('')
        .map((char) => {
          const c = char === ' ' ? '&nbsp;' : char
  
          return '<span>' + c + '</span>'
        })
        .join('')
  
      return html
    }

    addPlane() {
      this.geometry = new PlaneBufferGeometry(2, 2);

      this.material = new ShaderMaterial({
        uniforms: this.uniforms,
        vertexShader: `varying vec2 vUv;
        varying vec3 vPosition;
        
        void main() {	
          vUv = uv;
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,
        fragmentShader: other
      });

      this.uniforms.u_resolution.value.x = this.width;
      this.uniforms.u_resolution.value.y = this.height;

      this.material.needsUpdate = true;


      const cube = new Mesh( this.geometry, this.material );
      this.scene.add( cube )

      this.camera.position.z = 1;
    }

    events() {
      window.addEventListener('resize', this.resize.bind(this))

      for(const letter of this.titlesLetter) {
        letter.addEventListener('mouseenter', this.mouseEnterVariable.bind(this))
        letter.addEventListener('mouseleave', this.mouseLeaveVariable.bind(this))
      }

      for(const letterFooter of this.footerLetter) {
        letterFooter.addEventListener('mouseenter', this.mouseEnterVariable.bind(this))
        letterFooter.addEventListener('mouseleave', this.mouseLeaveVariable.bind(this))
      }
    }

    generateRandomDecimalInRangeFormatted(min, max, places) {
      let value = (Math.random() * (max - min));
      return Number.parseFloat(value).toFixed(places);
    }

    mouseEnterVariable(e) {
      e.target.classList.add('center');

      if(e.target.nextSibling) {
        e.target.nextSibling.classList.add('closeFirst')
        if (e.target.nextSibling.nextSibling) e.target.nextSibling.nextSibling.classList.add('closeSecond')
      } 
      if(e.target.previousSibling) {
        e.target.previousSibling.classList.add('closeFirst')
        if(e.target.previousSibling.previousSibling) e.target.previousSibling.previousSibling.classList.add('closeSecond')
      } 
    }

    mouseLeaveVariable(e) {
      e.target.classList.remove('center');

      if(e.target.nextSibling) {
        e.target.nextSibling.classList.remove('closeFirst')
        if (e.target.nextSibling.nextSibling) e.target.nextSibling.nextSibling.classList.remove('closeSecond')
      } 
      if(e.target.previousSibling) {
        e.target.previousSibling.classList.remove('closeFirst')
        if(e.target.previousSibling.previousSibling) e.target.previousSibling.previousSibling.classList.remove('closeSecond')
      } 

    }

    resize(){
      this.width = document.body.clientWidth;
      this.height = document.body.clientHeight;

      this.uniforms.u_resolution.value.x = this.width;
      this.uniforms.u_resolution.value.y = this.height;

      if(this.material) this.material.needsUpdate = true;

      this.renderer.setSize( this.width,this.height );
      this.camera.aspect = this.width/this.height;
      this.camera.updateProjectionMatrix();
    }

    
    render(currentTime){
      if(!this.startingTime) this.startingTime = currentTime;
      if(!this.lastTime) this.lastTime = currentTime;
      this.timeLoopMs = (currentTime-this.startingTime);
      this.timeLoop = this.timeLoopMs / 1000;
      this.deltaTimeLoop = (currentTime-this.lastTime) / 1000;
      this.lastTime = currentTime;

      
      const time = this.timeLoop;
      const deltaTime = this.deltaTimeLoop;

      const newDelta = (deltaTime * this.dividedDelta);
      window.requestAnimationFrame(this.render);

      this.time+=0.0075;

      console.log(newDelta, this.deltaTimeLoop)

      this.uniforms.u_time.value = this.time;
      this.renderer.render( this.scene, this.camera );
        
    }
}

new Sketch({
    dom: document.getElementById('container')
});

